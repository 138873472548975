import React, {useState, useEffect} from "react";
import { useStaticQuery, graphql } from 'gatsby';
import axios from "axios";
import Layout from "../layouts/Layout";
import {countriesList} from "../components/Travel/Countries";
import Header from "../components/Header";
import CountrySearch from "../components/Travel/CountrySearch";
// import Autocomplete from "../components/Travel/Autocomplete";

// import useCurrentLocation from "../components/Hooks/useCurrentLocation";
// import { geolocationOptions } from "../components/Constants/geolocationOptions";
//
// const storedLocation = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : null

// async function getAddress(setGeoLoc, latitude, longitude) {
//     let lat = `${latitude}`.split('.')
//     let lng = `${longitude}`.split('.')
//     lat = lat[0]+'.'+lat[1].slice(0,3)
//     lng = lng[0]+'.'+lng[1].slice(0,3)
//
//     if (storedLocation && lat === storedLocation.lat && lng === storedLocation.lng) {return};
//     const url = `https://eu1.locationiq.com/v1/reverse.php?key=${process.env.GATSBY_MAPS_KEY}&lat=${lat}&lon=${lng}&format=json`
//
//     axios.get(url)
//     .then(function (response) {
//         const location = response.data.address
//         setGeoLoc({
//             lat: lat,
//             lng: lng,
//             city: location.city,
//             state: location.state,
//             country: location.country
//         })
//         return location;
//       })
//       .catch((error) => {
//           const status = error.response.status
//           const msg = error.response.data.error
//           status === 404 ?
//               console.log("RESPONSE MESSAGE", status, msg)
//               :
//               console.log("ANOTHER TYPE OF ERROR MESSAGE", status, msg)
//       });
// }

const Travel = ({location}) => {
    const [searchExpanded, setSearchExpanded] = useState(true)

    const countryFlags = useStaticQuery(graphql`
        query countryFlags {
            allFile(filter: {
                extension: {
                    regex: "/(png)/"
                },
                relativeDirectory: {
                    eq: "flags"
                }
            }) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
        }
    `);

    // const { location: currentLocation, error: currentError } = useCurrentLocation(geolocationOptions);
    // const [geoLoc, setGeoLoc] = useState({
    //     lat: storedLocation ? storedLocation.lat : "",
    //     lng: storedLocation ? storedLocation.lng : "",
    //     city: storedLocation ? storedLocation.city : "",
    //     state: storedLocation ? storedLocation.state : "",
    //     country: storedLocation ? storedLocation.country : ""
    // })

    const flags = countryFlags.allFile.edges.map(a => a.node)
    let countries = []
    // const mergeArrayObjects = (countriesList,flags) => {
      countriesList.map((item, i)=>{
          flags.map(f => {
              if (f.name === item.alpha2) {
                  countries.push(Object.assign({},item,{flag: f.publicURL}))
              }
          })
      })
    // }

    // useEffect(
    //     () => {
    //         if(currentLocation === undefined) return;
    //         const address = getAddress(setGeoLoc, currentLocation.latitude, currentLocation.longitude)
    //     }, [currentLocation]
    // );
    //
    //
    // useEffect(
    //     () => {
    //         geoLoc.country.length && localStorage.setItem("location", JSON.stringify(geoLoc));
    //     }, [geoLoc]
    // )

    return (
        <Layout location={location}>
            <Header id="page-header" title="Covid Travel" animTrigger={!searchExpanded} />
            <CountrySearch searchExpanded={searchExpanded} setSearchExpanded={setSearchExpanded} countriesData={countries} flags={flags} />
        </Layout>
    )
}
export default Travel;
